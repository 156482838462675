<template>
  <dog-dialog
    ref="dogDialog"
    title="批量操作"
    @loading-confirm="confirm"
    @closed="closed"
    width="900px"
  >
    <div class="batchOperateDialog">
      <div
        v-for="(data, index) in row.operations"
        :key="index"
        class="operationItem"
      >
        <div class="add_delete">
          <i class="el-icon-circle-plus" @click="addFormData(index)"></i>
          <i
            class="el-icon-remove"
            @click="deleteFormData(index)"
            v-if="row.operations.length > 1"
          ></i>
        </div>
        <dog-form
          ref="dogForm"
          :form-data="data"
          :column-num="2"
          :col-rule="(row) => row.col || 12"
          :form-options="formOptions(data, index)"
        ></dog-form>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import productService from '@/api/service/product.js';
  import classService from '@/api/service/class';
  import singleImgUpload from '@/components/singleImgUpload.vue';
  import batchImgUpload from '@/components/batchImgUpload.vue';

  const fieldOptions = [
    { label: '标题', value: 'title' },
    { label: '主图', value: 'mainImg' },
    { label: '详情图', value: 'detailImg' },
    { label: '联系人', value: 'contact' },
    { label: '联系电话', value: 'phone' },
    { label: '设置启用', value: 'enable' },
    { label: '设置推荐', value: 'recommend' },
    { label: '设置型号', value: 'model' },
    { label: '设置分类', value: 'class' }
  ];

  class DefaultOperation {
    titleValue = '';
    contactValue = '';
    phoneValue = '';
    mainImgValue = [];
    detailImgValue = [];
    modelValue = [{}];
    classValue = [];
    enableValue = false;
    recommendValue = false;
  }

  export default {
    name: 'batchOperateDialog',
    data() {
      return {
        row: {
          operations: [{ ...new DefaultOperation() }]
        },
        productIds: [],
        companyId: null,
        treeData: [],
        modelFormOptions: [
          {
            type: 'input',
            formItem: {
              label: '型号',
              prop: 'name',
              rules: [this.$formRules.required('请输入型号')]
            }
          },
          {
            type: 'el-input-number',
            formItem: {
              label: '价格',
              prop: 'price',
              rules: [this.$formRules.required('请输入价格')]
            },
            attrs: {
              // default: 1,
              min: 0.01,
              step: 0.01,
              stepStrictly: true
            }
          },
          {
            type: 'el-input-number',
            formItem: {
              label: '原价',
              prop: 'oldPrice'
            },
            attrs: {
              // default: 1,
              min: 0.01,
              step: 0.01,
              stepStrictly: true
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'img',
              label: '图片'
            },
            component: singleImgUpload,
            col: 16
          },
          {
            type: 'switch',
            formItem: {
              label: '是否启用',
              prop: 'enable',
              rules: []
            },
            attrs: {
              default: true
            }
          }
        ]
      };
    },
    methods: {
      openFrame({ productIds = [], companyId = null }) {
        this.productIds = productIds;
        this.companyId = companyId;
        this.$refs.dogDialog.open();
        this.getTreeData();
      },
      getTreeData() {
        classService.getClassTree({ companyId: this.companyId }).then((res) => {
          this.treeData = res.map((item) => {
            return {
              ...item,
              disabled: true
            };
          });
        });
      },
      formOptions(formData, index) {
        let changeValue = [];
        const fieldLabel = (
          fieldOptions.find((item) => item.value === formData.field) || {}
        ).label;
        if (['title'].includes(formData.field)) {
          changeValue = [
            {
              type: 'input',
              formItem: {
                label: fieldLabel,
                prop: 'titleValue',
                rules: [this.$formRules.required(`请输入${fieldLabel}`)]
              },
              attrs: {
                'allow-space': true
              },
              col: 24
            }
          ];
        }
        if (['mainImg', 'detailImg'].includes(formData.field)) {
          changeValue = [
            {
              type: 'custom',
              formItem: {
                label: fieldLabel,
                prop:
                  formData.field === 'mainImg'
                    ? 'mainImgValue'
                    : 'detailImgValue',
                rules: [this.$formRules.required(`请上传${fieldLabel}`)]
              },
              component: batchImgUpload,
              col: 24
            }
          ];
        }
        if (['contact', 'phone'].includes(formData.field)) {
          changeValue = [
            {
              type: 'input',
              formItem: {
                label: fieldLabel,
                prop:
                  formData.field === 'contact' ? 'contactValue' : 'phoneValue'
                // rules: [this.$formRules.required(`请输入${fieldLabel}`)]
              },
              col: 24
            }
          ];
        }
        if (['enable', 'recommend'].includes(formData.field)) {
          changeValue = [
            {
              type: 'switch',
              formItem: {
                label: fieldLabel,
                prop:
                  formData.field === 'enable' ? 'enableValue' : 'recommendValue'
              },
              col: 24
            }
          ];
        }
        if (['model'].includes(formData.field)) {
          changeValue = [
            {
              type: 'custom',
              formItem: {
                label: fieldLabel,
                prop: 'modelValue',
                rules: [this.$formRules.required(`请输入${fieldLabel}`)]
              },
              render: (h, { props: { cur } }) => {
                return (
                  <div class='modelItem'>
                    {formData.modelValue.map((item, index) => {
                      return (
                        <div>
                          <div class='add_delete'>
                            <i
                              class='el-icon-circle-plus'
                              on-click={() => {
                                formData.modelValue.splice(index + 1, 0, {});
                                this.$forceUpdate();
                              }}></i>
                            {formData.modelValue.length > 1 && (
                              <i
                                class='el-icon-remove'
                                on-click={() => {
                                  formData.modelValue.splice(index, 1);
                                  this.$forceUpdate();
                                }}></i>
                            )}
                          </div>
                          <dog-form
                            ref='dogFormModel'
                            form-data={item}
                            column-num={3}
                            col-rule={(row) => row.col || 8}
                            form-options={this.modelFormOptions}></dog-form>
                        </div>
                      );
                    })}
                  </div>
                );
              },
              col: 24
            }
          ];
        }
        if (['class'].includes(formData.field)) {
          changeValue = [
            {
              type: 'custom',
              formItem: {
                label: fieldLabel,
                prop: 'classValue'
              },
              render: (h, { props: { cur } }) => {
                return (
                  <div>
                    <el-tree
                      ref='tree'
                      data={this.treeData}
                      props={{ label: 'name' }}
                      show-checkbox
                      node-key='id'
                      check-strictly={true}
                      default-expand-all={false}
                      on-check-change={(data, checked) => {
                        const existIndex = formData.classValue.findIndex(
                          (item) => {
                            return item.id === data.id;
                          }
                        );
                        // 选中且不存在
                        if (checked && existIndex === -1) {
                          formData.classValue.push(data);
                        } else if (!checked && existIndex > -1) {
                          // 未选中且存在
                          formData.classValue.splice(existIndex, 1);
                        }
                      }}
                      scopedSlots={{
                        default: ({ node, data }) => {
                          return (
                            <span class='el-tree-node__label' title={data.name}>
                              {data.name}
                            </span>
                          );
                        }
                      }}></el-tree>
                  </div>
                );
              },
              col: 24
            }
          ];
        }
        return [
          {
            type: 'select',
            formItem: {
              label: '修改的方式',
              prop: 'type',
              rules: [this.$formRules.required('请选择修改的方式')]
            },
            attrs: {
              options: [
                { label: '覆盖', value: 'cover' },
                { label: '追加', value: 'append' }
              ],
              default: 'cover',
              clearable: false,
              disabled: ['contact', 'phone', 'enable', 'recommend'].includes(
                formData.field
              )
            }
          },
          {
            type: 'select',
            formItem: {
              label: '修改的字段',
              prop: 'field',
              rules: [this.$formRules.required('请选择要修改的字段')]
            },
            attrs: {
              options: fieldOptions,
              clearable: false
            },
            listeners: {
              input: (val) => {
                if (['contact', 'phone', 'enable', 'recommend'].includes(val)) {
                  formData.type = 'cover';
                }
              }
            }
          },
          ...changeValue
        ];
      },
      addFormData(index) {
        this.row.operations.splice(index + 1, 0, {
          ...new DefaultOperation()
        });
      },
      deleteFormData(index) {
        this.row.operations.splice(index, 1);
      },
      confirm(done) {
        for (let i = 0; i < this.row.operations.length; i++) {
          if (this.row.operations[i].field === 'model') {
            if (
              !this.row.operations[i].modelValue.every(
                (item) => item.name && item.price
              )
            ) {
              this.$message.error('型号和价格请填写完整！');
              done();
              return;
            }
          }
        }
        const newListLength = new Set(
          this.row.operations.map((item) => item.field)
        ).size;
        if (this.row.operations.length > newListLength) {
          this.$message.error('存在重复的待修改字段，请合并重复字段后再提交！');
          done();
          return;
        }
        const formList = [...this.$refs.dogForm];
        Promise.all([
          ...formList.map((item) => {
            return item.validate();
          })
        ])
          .then(() => {
            return productService.batchOperateProduct({
              ids: this.productIds,
              operations: this.row.operations.map((item) => {
                return {
                  type: item.type,
                  field: item.field,
                  value: this.dealWithData(item.field, item)
                };
              })
            });
          })
          .then(() => {
            this.close();
            this.$emit('confirm');
          })
          .finally(done);
      },
      dealWithData(field, item) {
        const valueMap = {
          title: item.titleValue,
          contact: item.contactValue,
          phone: item.phoneValue,
          mainImg: item.mainImgValue || [],
          detailImg: item.detailImgValue || [],
          model: item.modelValue.map((subItem) => {
            return {
              ...subItem,
              enable: this.$methods.booleanToStringNum(subItem.enable)
            };
          }),
          class: item.classValue.map((subItem) => {
            return subItem.id;
          }),
          enable: this.$methods.booleanToStringNum(item.enableValue),
          recommend: this.$methods.booleanToStringNum(item.recommendValue)
        };
        return valueMap[field];
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.row = {
          operations: [
            {
              ...new DefaultOperation()
            }
          ]
        };
        this.productIds = [];
        this.companyId = null;
      }
    }
  };
</script>

<style lang="less" scoped>
  /deep/ .add_delete {
    margin-bottom: 10px;
    text-align: right;
  }
  /deep/ .el-icon-circle-plus {
    font-size: 25px;
    color: #0080ff;
    cursor: pointer;
  }
  /deep/ .el-icon-remove {
    margin-left: 5px;
    font-size: 25px;
    color: red;
    cursor: pointer;
  }
  .operationItem {
    margin-bottom: 10px;
  }
  /deep/ .modelItem {
    position: relative;
    right: 40px;
  }
</style>
